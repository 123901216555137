import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import FallBackLoader from "./components/FallBackLoader";
import DeletedUsers from "./Users/DeletedUsers";
import FreePlanLogs from './Meta/FreePlanLogs'
import AuthLogs from "./admin/AuthLogs";
import AllAdmin from "./admin/AllAdmin";
import ViewMoreAdmin from "./admin/ViewMoreAdmin";
import Referals from "./Meta/refer/Referals";
import { useSelector } from "react-redux";
import CountryCurrency from "./Meta/country-list/CountryCurrency";
import EditPricing from "./Meta/country-list/EditPricing";
import CreateBlog from "./Meta/blog/CreateBlog";
import ViewAllBlog from "./Meta/blog/ViewAllBlog";
import EditBlog from "./Meta/blog/EditBlog";
import UserFeedBack from "./Meta/UserFeedBack";
import UserKpi from "./components/kpi/UserKpi";

const ActivePremium = React.lazy(()=>import("./Subscription/ActivePremium"))
const Login = React.lazy(()=>import('./Auth/Login.jsx'))
const Home = React.lazy(()=>import('./home/Home.jsx'))
const ProtectedRoutes = React.lazy(()=>import('./components/ProtectedRoutes'))
const Alluser = React.lazy(()=>import('./Users/Alluser.jsx'))
const Reported = React.lazy(()=>import('./Users/Reported.jsx'))
const PremiumUser = React.lazy(()=>import('./Subscription/PremiumUser.jsx'))
const General = React.lazy(()=>import('./Meta/General.jsx'))
const ViewMore = React.lazy(()=>import('./Users/ViewMore.jsx'))
const ActiveUsers = React.lazy(()=>import('./Users/ActiveUsers.jsx'))
const Error404 = React.lazy(()=>import('./Error404.jsx'))

function App() {
  const { status,data } = useSelector((state) => state.getAdminProfile);
  const [getPermission, setPermission] = useState([]);
  const [admin, setAdmin] = useState(2);

  useEffect(() => {
    setPermission(localStorage.getItem("permission"));
    setAdmin(localStorage.getItem("superAdmin"));
  }, [status,data]);
  
  return (
    <div className="App">
      <Router>
        <Suspense fallback ={<FallBackLoader/>}>
        <Routes>
          <Route element={<Login></Login>} path="/" />
          <Route element={<ProtectedRoutes/>}>
            <Route element={<Home></Home>} path="/home" />
            <Route  element={Number(admin) === 1 || (getPermission && getPermission.includes("all-users")) ? <Alluser/> : <Navigate to="*"/>} path="/users" />
            <Route element={Number(admin) === 1 || (getPermission && getPermission.includes("view-user")) ? <ViewMore/> : <Navigate to="*"/>} path="/viewmore/:userId" />
            <Route element={ Number(admin) === 1 || ( getPermission && getPermission.includes("view-admin")) ?<ViewMoreAdmin/> : <Navigate to="*"/>} path="/viewmore/admin/:userId" />
            <Route element={ Number(admin) === 1 || ( getPermission && getPermission.includes("reported-users")) ? <Reported/> : <Navigate to="*"/>} path="/report" />
            <Route element={Number(admin) === 1 || ( getPermission && getPermission.includes("premium-history")) ?<PremiumUser/> : <Navigate to="*"/>} path="/premiumuser" />
            <Route element={Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? <General/> : <Navigate to="*"/>} path="/general" />
            <Route element={Number(admin) === 1 || (getPermission && getPermission.includes("active-premium")) ?<ActivePremium/> : <Navigate to="*"/>} path="/active-subscription" />
            <Route element = { Number (admin) === 1 || (getPermission && getPermission.includes("active-users")) ? <ActiveUsers/> : <Navigate to="*"/>} path="/active/users"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("deleted-users")) ? <DeletedUsers/> : <Navigate to="*"/>} path="/deleted/users"/>
            <Route element = {Number(admin) === 1 ||( getPermission && getPermission.includes("freeplan-logs")) ? <FreePlanLogs/> : <Navigate to="*"/>} path="/free-plan-logs"/>
            <Route element = {Number(admin) === 1 ||( getPermission && getPermission.includes("auth-logs")) ?<AuthLogs/> : <Navigate to="*"/>} path="/admin-logs"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("refer")) ? <Referals/> : <Navigate to="*"/>} path="/refer"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("admin")) ? <AllAdmin/> : <Navigate to="*"/>} path="/all-admin"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? <CountryCurrency/> : <Navigate to="*"/>} path="/upload/country/currency"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? <UserFeedBack/> : <Navigate to="*"/>} path="/userFeedBack"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? <EditPricing/> : <Navigate to="*"/>} path="/edit/country/currency/:countryId"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? <CreateBlog/> : <Navigate to="*"/>} path="/create/blog"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? <ViewAllBlog/> : <Navigate to="*"/>} path="/view/all/blog"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? <EditBlog/> : <Navigate to="*"/>} path="/edit/blog/:blogId"/>
            <Route element = {Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? <UserKpi/> : <Navigate to="*"/>} path="/UserStats"/>
          </Route>
          <Route element = {<Error404/>} path="*"/>
        </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
